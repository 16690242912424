import { Grid, TextField, CircularProgress, Button, Typography, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles"
import { useState } from "react";
import { updateSubscription } from '../../api/AdminAPI';
import { subscriptionTypes } from "../../constants/subscriptionTypes";

const useStyles = makeStyles((theme) => ({
  maincontainer: {
    maxHeight: '80vh',
    overflowY: 'scroll'
  },
  button: {
    marginLeft: "1rem"
  },
}));

const TextFieldEditable = props => {
  const {name, label, value="", onChange} = props;

  return (
    <TextField
      variant="outlined"
      margin="normal"
      fullWidth
      required
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      inputProps={{
        maxLength: 50,
      }}
      error={String(value)?.trim() === ""}
      helperText={String(value)?.trim() === "" ? "Required" : ""}
    />
  )
}

const SubscriptionCustomerInfoEdit = props => {
  const classes = useStyles();
  const subscription = props?.subscription;
  const onClose = props?.onClose;
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: subscription?.email ? subscription?.email : "",
    firstName: subscription?.firstName ? subscription?.firstName : "",
    lastName: subscription?.lastName ? subscription?.lastName : "",
    streetAddress: subscription?.streetAddress ? subscription?.streetAddress : "",
    city: subscription?.city ? subscription?.city : "",
    state: subscription?.state ? subscription?.state : "",
    zip: subscription?.zip ? subscription?.zip : "",
    phoneNumber: subscription?.phoneNumber ? subscription?.phoneNumber : "",
    amount: subscription?.amount ? subscription?.amount : "",
    accountNumber: subscription?.accountNumber ? subscription?.accountNumber : "",
    optionSelected: subscription?.optionSelected ? subscription?.optionSelected : "",
    businessName: subscription?.businessName ? subscription?.businessName : "",
    billOption: subscription?.billOption ? subscription?.billOption : "",
    AECPercentage: subscription?.AECPercentage ? subscription?.AECPercentage : "",
  });

  const isCommercial = subscription.customerType === 'Commercial';

  const handleChange = (event) => {    
    let key = event.target.name;
    let value = event.target.value;
    setFormData({
        ...formData,
        [key]: value
    });
  };

  const validateRequired = () => {
    let required = (formData.email
      && formData.firstName
      && formData.lastName
      && formData.streetAddress
      && formData.city
      && formData.state
      && formData.zip
      && formData.phoneNumber
      && formData.accountNumber
    );

    if (isCommercial) {
      required = required && formData.businessName;
    }if (isAmountRequired(subscription.subscriptionType)){
      required = required && formData.amount
    }if(isOptionRequired(subscription.subscriptionType)){
      required = required && formData.optionSelected
    }if(isBillOptionRequired(subscription.subscriptionType)){
      required = required && formData.billOption
    }if(isAECPercentageRequired(subscription.subscriptionType)){
      required = required && formData.AECPercentage
    }

    return required;
  }

  const validateEmailFormat = (email) => {
    const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:|\\)*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:|\\)+)\])/;
    return regex.test(email)
  }

  const enableSubmit = () => {
    return validateRequired()
        && validateEmailFormat(formData.email)

  }

  const handleSubmit = () => {
    setLoading(true);    
    updateSubscription(subscription?.id, formData).then(() => {
        setLoading(false);
        onClose();
    }).catch((error) => {
        setLoading(false);
        console.log(error);
    });
  }

  const isAmountRequired = (program) => {
    if((program === subscriptionTypes.GO_ZERO && !formData.optionSelected.includes('Option 1 - RECS')) || program === subscriptionTypes.GREEN_SELECT || program === subscriptionTypes.GREEN_PRICING) return false
    else return true
  }

  const isOptionRequired = (program) => {
    if(program === subscriptionTypes.GS_LARGE_VOLUME) return false
    else return true
  }

  const isBillOptionRequired = (program) => {
    if(program === subscriptionTypes.GO_ZERO && formData.optionSelected.includes('Option 1 - RECS')) return true
    else return false
  }

  const isAECPercentageRequired = (program) => {
    if(program === subscriptionTypes.GO_ZERO && formData.optionSelected.includes('Option 2 - AECS')) return true
    else return false
  }

  const getOptions = (program) => {
    switch(program){
      case 'Green Select':
      case 'Green Pricing':
        return [{label: 'Tier 1', value: 'tier1'}, {label: 'Tier 2', value: 'tier2'}, {label: 'Tier 3', value: 'tier3'}]
      case 'Green Future':
        return [{label: 'Commercial', value: 'Commercial'}, {label: 'Residential', value: 'Residential'}, {label: 'Low Income', value: 'Low Income'}]
      case 'RCO':
        return [{label: '1 Year', value: '1yr'}]
      case 'Geaux Green':
        return [{label: 'Varibale Price', value: 'variablePrice'}, {label: 'Fixed Price', value: 'fixedPrice'}]
      case 'Green Promise':
        return [{label: '1 Year', value: '1yr'}, {label: '10 Year', value: '10yr'}, {label: 'Low Income', value: 'low-income'}]
      case 'Go Zero':
        return [
          {label: 'Option 1 - RECS', value: 'Option 1 - RECS'}, 
          {label: 'Option 2 - AECS', value: 'Option 2 - AECS'}, 
          {label: 'Option 3 - Time-match Reporting', value: 'Option 3 - Time-match Reporting'},
          {label: 'Bundle Option 1 - RECS and Option 2 - AECS', value: 'Option 1 - RECS, Option 2 - AECS'}, 
          {label: 'Bundle Option 1 - RECS and Option 3 - Time-match Reporting', value: 'Option 1 - RECS, Option 3 - Time-match Reporting'}, 
          {label: 'Bundle Option 2 - AECS and Option 3 - Time-match Reporting', value: 'Option 2 - AECS, Option 3 - Time-match Reporting'}, 
          {label: 'Bundle Option 1 - RECS, Option 2 - AECS, and Option 3 - Time-match Reporting', value: 'Option 1 - RECS, Option 2 - AECS, Option 3 - Time-match Reporting'}
        ]
      default:
        return []
    }
  }

  return (
    <div>
      <form noValidate autoComplete="false" onSubmit={() => handleSubmit()}>
        <Grid container spacing={1} className={classes.maincontainer}>
          <Grid item xs={12}>
            <Typography variant="h4">Edit Subscription</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider/>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Subscription Information</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextFieldEditable value={formData.accountNumber} name="accountNumber" label="Account Number" onChange={handleChange} />
          </Grid>
          {isAmountRequired(subscription.subscriptionType) &&
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                required
                name={'amount'}
                label={'Subscription Amount'}
                value={formData.amount}
                onChange={handleChange}
                inputProps={{
                  maxLength: 50,
                }}
                error={String(formData.amount)?.trim() === "" && isAmountRequired(subscription.subscriptionType)}
                helperText={String(formData.amount)?.trim() === "" && isAmountRequired(subscription.subscriptionType) ? "Required" : ""}
              />
            </Grid>
          }
          {isOptionRequired(subscription.subscriptionType) &&
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                required
                select
                name="optionSelected"
                label="Option Selected"
                value={formData.optionSelected}
                onChange={handleChange}
                SelectProps={{
                    native: true,
                  }}
                error={formData.optionSelected?.trim() === ""}
                helperText={formData.optionSelected?.trim() === "" ? "Required" : ""}
              >   
                <option key={""} value={""}>
                    {""}
                </option>   
                {getOptions(subscription.subscriptionType).map(option => 
                  <option key={option.label} value={option.value}>
                    {option.label}
                </option>
                )}
              </TextField>
            </Grid>
          }
          {isBillOptionRequired(subscription.subscriptionType) &&
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                required
                select
                name="billOption"
                label="Bill Option"
                value={formData.billOption}
                onChange={handleChange}
                SelectProps={{
                    native: true,
                  }}
                error={formData.billOption?.trim() === ""}
                helperText={formData.billOption?.trim() === "" ? "Required" : ""}
              >   
                <option key={""} value={""}>
                    {""}
                </option> 
                <option key={"REC Rate"} value={"1A"}>
                    {"REC Rate"}
                </option> 
                <option key={"Monthly Subscription Rate"} value={"1B"}>
                    {"Monthly Subscription Rate"}
                </option> 
                <option key={"FLMP Rate"} value={"1C"}>
                    {"FLMP Rate"}
                </option>   
              </TextField>
            </Grid>
          }
          {isAECPercentageRequired(subscription.subscriptionType) &&
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                required
                select
                name="AECPercentage"
                label="AEC Percentage"
                value={formData.AECPercentage}
                onChange={handleChange}
                SelectProps={{
                    native: true,
                  }}
                error={formData.AECPercentage?.trim() === ""}
                helperText={formData.AECPercentage?.trim() === "" ? "Required" : ""}
              >   
                <option key={""} value={""}>
                    {""}
                </option>
                <option key={"25%"} value={"25"}>
                    {"25%"}
                </option>
                <option key={"50%"} value={"50"}>
                    {"50%"}
                </option>
                <option key={"75%"} value={"75"}>
                    {"75%"}
                </option>   
                <option key={"100%"} value={"100"}>
                    {"100%"}
                </option>
              </TextField>
            </Grid>
          }
          <Grid item xs={12}>
            <Typography variant="h6">Customer Information</Typography>
          </Grid>
          {isCommercial && 
            <Grid item xs={12}>
              <TextFieldEditable value={formData.businessName} name="businessName" label="Business Name" onChange={handleChange} />
            </Grid>
          }
          <Grid item xs={12} sm={6}>
            <TextFieldEditable value={formData.firstName} name="firstName" label="First Name" onChange={handleChange} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextFieldEditable value={formData.lastName} name="lastName" label="Last Name" onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldEditable value={formData.streetAddress} name="streetAddress" label="Address" onChange={handleChange} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextFieldEditable value={formData.city} name="city" label="City" onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldEditable value={formData.state} name="state" label="State" onChange={handleChange} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextFieldEditable value={formData.zip} name="zip" label="Zip" onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldEditable value={formData.phoneNumber} name="phoneNumber" label="Phone Number" onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldEditable value={formData.email} name="email" label="E-mail" onChange={handleChange} />
          </Grid>
          <Grid container justifyContent="flex-end">
            <Button 
              className={classes.button} 
              disabled={loading} 
              onClick={onClose} 
              variant="outlined" 
              color="primary">
                Cancel
            </Button>
            {loading ? 
              <CircularProgress color="primary" size={36} /> 
              :
              <Button
                className={classes.button}
                id="submit"
                type="submit"
                variant="contained"
                color="primary"
                disabled={!enableSubmit()}
                onClick={handleSubmit}>
                  Update Subscription
              </Button>
            }
          </Grid>
        </Grid>
      </form>
    </div>
  )
};
export default SubscriptionCustomerInfoEdit;